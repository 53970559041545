import { Col, Collapse, Row } from 'antd';
import { H1Bold, H2Bold, H3Bold, TextSmall, TextXsmall } from '../shared/typography';

import { BtnSecoundary } from '../shared/button';
import { GatsbyImage } from "gatsby-plugin-image";
import { IFAQPage } from './faq.interface';
import React from 'react'
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { theme } from '../shared/theme';
import useWindowWidth from '../shared/useWindowSize';

const StyledCollapse = styled(Collapse)`

  .ant-collapse-header {
    background:  ${theme.colors.bgLight.white};
    font-size: 1.125rem;
    border-top: 1px solid ${theme.colors.brandLight.secoundary};
    padding: 2rem 1.5rem !important;

    .anticon {
      font-size: 1.125rem !important;
    }
  }

  .ant-collapse-item {
    border-bottom: 1px solid ${theme.colors.brandLight.secoundary};
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      border-bottom: none;

    }
  }
`;

const CollapsePanel = styled(Collapse.Panel)`
    border: 1px solid ${theme.colors.brandLight.secoundary};
    border-top: none;
    margin-bottom: 1.5rem;

`


const StyledImg = styled(GatsbyImage)`
  width: 90%;
  max-height: 500px;
  margin: 3rem 0;
`;

type Props = {
  data: IFAQPage
}

const FAQComponent: React.FC<Props> = ({data}: Props) => {
    const width = useWindowWidth();

    return (
      <div style={{marginTop: '3rem'}}>
      {width > 750 && <Row style={{padding: '0 2rem'}}>
        <Col span={8} style={{display: 'flex'}}>
          <div style={{alignSelf: 'center'}}>
            <H3Bold style={{color: theme.colors.greyLight.grey15}}>FAQ</H3Bold>
            <H1Bold>{data.title}</H1Bold>
            <TextSmall>{data.subtitle.subtitle}</TextSmall>
          </div>
        </Col>
        <Col span={14} offset={2}>
        <StyledImg image={data.image.gatsbyImageData} alt={data.title} objectFit='contain' />
        </Col>
      </Row>}
      <Row style={{ padding: '3rem 0', marginBottom: '4rem' }} justify="center">
        <Col span={24} style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
          <H2Bold style={{textAlign: 'center', color: theme.colors.txtLight.black}}>{data.secondaryTitle}</H2Bold>
          <TextXsmall style={{textAlign: 'center', width: '100%'}}>{data.secondarySubtitle.secondarySubtitle}</TextXsmall>
          <BtnSecoundary style={{width: 'fit-content', padding: '0.5rem 4rem'}}  onClick={() => navigate('/kontakt')}>Kontakt</BtnSecoundary>
        </Col>
        <Col xs={{ span: 22 }} sm={{span: 20}} md={{ span: 18, offset: 1 }} style={{marginTop: '3rem'}}>
          <StyledCollapse
            bordered={false}
            expandIconPosition="right"
            style={{ background: 'white' }}
          >
            {data.faqs &&
              data.faqs.map((faq, key) => {
                return (
                  <CollapsePanel header={faq.title} key={key}>
                    <TextSmall>{faq.answer.answer}</TextSmall>
                  </CollapsePanel>
                );
              })} 
          </StyledCollapse>
        </Col>
      </Row>
      </div>
    );
  };
  export default FAQComponent;