import FAQComponent from '../components/faq/faq-component';
import { IFAQPage } from '../components/faq/faq.interface';
import React from 'react';
import Seo from '../components/Seo';
import { StyledContent } from '../components/layout/layout';
import { graphql } from 'gatsby';

interface Props {
  data: {
    allContentfulFaqPage: {
      nodes: IFAQPage[];
    };
  };
}

const FAQ: React.FC<Props> = ({ data }: Props) => {
  return (
    <StyledContent>
      <Seo
        title={'FAQ - spørgsmål og svar'}
        description='Spørgsmål og svar på ofte stillede spørgsmål. Finder du ikke det svar du søger er du altid velkommen til at kontakte mansted wine direkte'
        image={'https://www.mansted-wine.dk/logo/mansted-wine-logo.jpg'}
      />
      <FAQComponent data={data.allContentfulFaqPage.nodes[0]} />
    </StyledContent>
  );
};

export default FAQ;

export const RecipeQuery = graphql`
  query {
    allContentfulFaqPage {
      nodes {
        secondarySubtitle {
          secondarySubtitle
        }
        secondaryTitle
        subtitle {
          subtitle
        }
        title
        faqs {
          answer {
            answer
          }
          title
        }
        image {
          gatsbyImageData(width: 800, placeholder: BLURRED)
        }
      }
    }
  }
`;
